import React, { useRef } from "react"
import { Helmet } from "react-helmet"
// import * as style from "./style.module.scss"

const MainLayout = ({ children }: { children: React.ReactElement }) => {
  return (
    <>
      <Helmet title="GIMS">
        <meta
          name="viewport"
          content="width=device-width,viewport-fit=cover,initial-scale=1,shrink-to-fit=no,maximum-scale=1,user-scalable=0"
        />
        <link rel="stylesheet" type="text/css" href="/font/satoshi.css" />
      </Helmet>
      {children}
    </>
  )
}

export default MainLayout
